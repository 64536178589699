export default {
  UPDATE_BET_TICKER_LIST: 'UPDATE_BET_TICKER_LIST',
  SET_BET_TICKER_LIST_LOADING: 'SET_BET_TICKER_LIST_LOADING',
  UPDATE_BET_TICKER_SEARCH: 'UPDATE_BET_TICKER_SEARCH',
  SET_ACTIVE_SIDEBAR_OPTION: 'SET_ACTIVE_SIDEBAR_OPTION',
  SET_SELECTED_SEARCH_FIELD: 'SET_SELECTED_SEARCH_FIELD',
  SET_BET_TICKER_CONFIGURATIONS: 'SET_BET_TICKER_CONFIGURATIONS',
  SET_ALL_SPORTS_DISPLAY_MARKETS_CONFIGURATION: 'SET_ALL_SPORTS_DISPLAY_MARKETS_CONFIGURATION',
  SELECT_BET_TICKER: 'SELECT_BET_TICKER',
  SHOW_DISABLED_TICKERS: 'SHOW_DISABLED_TICKERS',
  SET_ONLY_FLAGGED_BETS: 'SET_ONLY_FLAGGED_BETS',

  UPDATE_LIABILITY_EVENTS: 'UPDATE_LIABILITY_EVENTS',
  SET_LIABILITY_EVENTS_LOADING: 'SET_LIABILITY_EVENTS_LOADING',
  UPDATE_LIABILITY_FILTERS_META: 'UPDATE_LIABILITY_FILTERS_META',

  UPDATE_LIABILITY_EVENT: 'UPDATE_LIABILITY_EVENT',
  SET_LIABILITY_EVENT_LOADING: 'SET_LIABILITY_EVENT_LOADING',
  SET_LIABILITY_DISPLAY_MARKETS_CONFIGURATION: 'SET_LIABILITY_DISPLAY_MARKETS_CONFIGURATION',
  SET_LIABILITY_EVENT_MARKETS: 'SET_LIABILITY_EVENT_MARKETS',
  SET_LIABILITY_EVENT_SELECTIONS_BY_MARKET_ID: 'SET_LIABILITY_EVENT_SELECTIONS_BY_MARKET_ID',
  SET_LIABILITY_MARKET_GROUPS: 'SET_LIABILITY_MARKET_GROUPS',

  SET_ALL_CUSTOMERS_IS_INITIALIZING: 'SET_ALL_CUSTOMERS_IS_INITIALIZING',
  SET_ALL_CUSTOMERS_IS_LOADING: 'SET_ALL_CUSTOMERS_IS_LOADING',
  SET_ALL_CUSTOMERS_INITIALIZED: 'SET_ALL_CUSTOMERS_INITIALIZED',
  SET_ALL_CUSTOMERS_FILTERS: 'SET_ALL_CUSTOMERS_FILTERS',
  SET_ALL_CUSTOMERS_PAGE: 'SET_ALL_CUSTOMERS_PAGE',
  SET_ALL_CUSTOMERS_LIMIT: 'SET_ALL_CUSTOMERS_LIMIT',
  SET_ALL_CUSTOMERS_COLUMNS: 'SET_ALL_CUSTOMERS_COLUMNS',
  SET_ALL_CUSTOMERS_TOTAL_COUNT: 'SET_ALL_CUSTOMERS_TOTAL_COUNT',
  SET_ALL_CUSTOMERS_DATA: 'SET_ALL_CUSTOMERS_DATA',
  SET_ALL_CUSTOMERS_SIDEBAR: 'SET_ALL_CUSTOMERS_SIDEBAR',

  SET_CUSTOMER_IS_INITIALIZING: 'SET_CUSTOMER_IS_INITIALIZING',
  SET_CUSTOMER_IS_LOADING: 'SET_CUSTOMER_IS_LOADING',
  SET_CUSTOMER_KEY: 'SET_CUSTOMER_KEY',
  SET_CUSTOMER_DATA: 'SET_CUSTOMER_DATA',
  SET_CUSTOMER_SIDEBAR: 'SET_CUSTOMER_SIDEBAR',
  SET_CUSTOMER_EDIT_NICKNAME: 'SET_CUSTOMER_EDIT_NICKNAME',
  SET_CUSTOMER_BETS_IS_LOADING: 'SET_CUSTOMER_BETS_IS_LOADING',
  SET_CUSTOMER_BETS_FILTERS: 'SET_CUSTOMER_BETS_FILTERS',
  SET_CUSTOMER_BETS_PAGE: 'SET_CUSTOMER_BETS_PAGE',
  SET_CUSTOMER_BETS_LIMIT: 'SET_CUSTOMER_BETS_LIMIT',
  SET_CUSTOMER_BETS_COLUMNS: 'SET_CUSTOMER_BETS_COLUMNS',
  SET_CUSTOMER_BETS_TOTAL_COUNT: 'SET_CUSTOMER_BETS_TOTAL_COUNT',
  SET_CUSTOMER_BETS_DATA: 'SET_CUSTOMER_BETS_DATA',
};
